/*..........commone..........*/


/*.....forn.........*/

.t-30-bold {
    font-size: 30px !important;
    font-style: normal;
    font-weight: 700 !important;
    color: #000 !important;
    word-break: break-word;
}

.t-25-bold {
    font-size: 25px !important;
    color: black !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-24-bold {
    font-size: 24px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-22-bold {
    /*color: #000 !important;*/
    font-size: 22px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-20-bold {
    /*color: #000 !important;*/
    font-size: 20px !important;
    word-break: break-word;
    font-weight: 600 !important;
}

.t-20-500 {
    /*color: #000 !important;*/
    font-size: 20px !important;
    word-break: break-word;
    font-weight: 500 !important;
}

.t-19-bold {
    font-size: 19px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-19-500 {
    font-size: 19px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-18-bold {
    font-size: 18px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-18-500 {
    font-size: 18px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-16-bold {
    color: black;
    font-size: 16px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-15-bold {
    font-size: 15px !important;
    font-weight: 700 !important;
    word-break: break-word;
}

.t-15-500 {
    font-size: 15px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-16-bold {
    font-size: 16px !important;
    word-break: break-word;
    font-weight: 600 !important;
}

.t-16-500 {
    font-size: 16px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-14-bold {
    font-size: 14px !important;
    word-break: break-word;
    font-weight: 600 !important;
}

.t-14-500 {
    font-size: 14px !important;
    word-break: break-word;
    font-weight: 500 !important;
}

.t-13-bold {
    font-size: 13px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-13-500 {
    font-size: 13px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-12-bold {
    font-size: 12px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-12-500 {
    font-size: 12px !important;
    font-weight: 500 !important;
    word-break: break-word;
}

.t-10-bold {
    font-size: 12px !important;
    font-weight: 600 !important;
    word-break: break-word;
}

.t-10-500 {
    font-size: 12px !important;
    word-break: break-word;
    font-weight: 500 !important;
}

.yelloe-t-bold {
    color: #E19E19 !important;
    font-size: 12px !important;
    font-weight: 700 !important;
    word-break: break-word;
}


.text-black {
    color: black !important;
}

.text-gray {
    color: #636363 !important;
}

.text-dark-gray {
    color: #585858 !important;
}

h3, h1, h2, h4, h5, h6 {
    word-break: break-word;
}


/*............box_shadow.............*/

.box_shadow {
    box-shadow: 0px 0px 10px 0px rgba(172, 209, 217, 0.73);
    transition-duration: 0.5s;
}

.box_shadow_light {
    box-shadow: -8px 0px 29px 0px rgba(0, 0, 0, 0.03);
    transition-duration: 0.5s;
}

.box_shadow:hover {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
}


/*............border........*/

.yellow_border {
    border: 2px solid #FFA500;
    cursor: pointer;
}

.grey_border {
    border: 1px solid #D0DEE2;
}

.black_border {
    border: 1.5px solid rgba(0, 0, 0, 0.20);
    border-radius: 10px;
}


.showArrow::-webkit-outer-spin-button,
.showArrow::-webkit-inner-spin-button {
    -webkit-appearance: button;
    margin-left: 10px;
    /*margin: 10px;*/
}


/*...............calander..............*/

.custom-calendar {
    box-shadow: none !important;
    background-image: url(../images/calanderBg.png) !important;
}

.custom-today-day {
    /*color: black !important;*/
    border: 1px solid #5CCBEA !important;
    border-radius: 10px !important;
}

.custom-today-day::after {
    /*visibility: hidden; !* hide small border under the text *!*/
}

.custom-selected-day {
    border-radius: 9px !important;
    background: linear-gradient(270deg, #DAE1AE 0%, #ABD5B4 99.79%) !important;
    color: black !important;
}

.purpleDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected):after {
    content: "\A";
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #11B5D9;
    display: inline-block;
    margin-left: -9px;
    margin-top: 6px;
    margin-bottom: -20px;
}

.yellowDay:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
    background: #FFA50080 !important;
    color: #000 !important;
    border-radius: 10px !important;
}

.css-bkzwk2-MuiStack-root {
    padding-top: 0 !important;
}


/*.................carousel...................*/

.react-multi-carousel-dot button {
    background: #FFA50030 !important;
    border: none !important;
}

.custom-dot-list-style {
    margin-top: 50px !important;
}

.react-multi-carousel-dot--active button {
    background: Orange !important;
}


.carousel-margin, .react-multi-carousel-track {
    margin-bottom: 30px !important;
}


@media only screen and (max-width: 425px) {
    .t-19-500 {
        font-size: 16px !important;
    }

    .t-25-bold {
        font-size: 20px !important;
    }

    .t-30-bold {
        font-size: 20px !important;
    }

    .t-20-bold {
        font-size: 17px !important;
        font-weight: 600 !important;
        width: 295px;
    }

    .small-20-bold {
        font-size: 15px !important;
        font-weight: 600 !important;
        width: 295px;
    }

    .t-24-bold {
        font-size: 20px !important;
        font-weight: 600 !important;
        word-break: break-word;
    }

    .t-16-bold {
        color: black;
        font-size: 14px !important;
        font-weight: 600 !important;
    }

    .t-15-bold {
        font-size: 14px !important;
        font-weight: 700 !important;
    }

    .t-15-500 {
        font-size: 14px !important;
        font-weight: 500 !important;
    }


    .t-22-bold {
        /*color: #000 !important;*/
        font-size: 19px !important;
        font-weight: 600 !important;
    }

    .t-18-bold {
        font-size: 16px !important;
    }

    .t-18-500 {
        font-size: 14px !important;
    }
}


.antdAutoComplate .ant-select-selection-placeholder {
    margin-top: 5px !important;
}

.antdAutoComplate .ant-select-selector {
    height: 46px !important;
}

.antdAutoComplate .ant-select-selection-search-input {
    margin-top: 5px !important;
}


.share .react-multi-carousel-item {
    text-align: center;
}

.share .react-multi-carousel-track {
    margin-bottom: 0 !important;
}

/*.........btn-loader......*/

.inner-gred .country-list ul li {
    margin-bottom: 0 !important;
}

.Congratulations_msg .custom-dot-list-style {
    margin-top: 0 !important;
}

.Congratulations_msg .react-multi-carousel-track {
    margin-bottom: 0 !important;
}

.react-tel-input .country-list .country {
    margin-bottom: 0 !important;
}

.Calendar__day.-ltr.-selectedStart {
    border-radius: 10px !important;
    color: black !important;
}

.Calendar__day.-ltr.-selectedEnd {
    border-radius: 10px !important;
    color: black !important;
}

.control-dots {
    display: none;
}

.tran_user_img {
    width: 33px;
    /* height: 37px; */
    border-radius: 5px;
    margin: 0;
    /* margin-left: 9px; */
    margin-right: 10px;
    padding: 0;
}

.ant-picker {
    padding: 14px 21px 14px !important;
    font-size: 18px !important;
}

.c-pointer {
    cursor: pointer;
}

.ref-user-input {
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    background: #F9F9F9;
    padding: 12px 20px;
    width: 100% !important;
    margin-top: 10px;
}

.input-placeholder {
    font-size: 14px;
    font-weight: 500;
    color: #696372;
}

.reffer-search-input {
    outline: none;
    width: 100%;
    border: none;
    font-size: 14px;
}

.select-box {
    background: #F9F9F9;
    padding: 20px;
}

.ref-search-input-box {
    border: 1px solid #C3D3E2;
    border-radius: 5px;
    padding: 8px 4px 8px 12px;
    width: 50%;
    background: white;
    display: flex;
    gap: 5px;
}

.search-user-name {
    color: #797979;
    font-weight: 500;
    font-size: 16px;
}

.search-user-btn {
    width: 50%;
    text-align: start;
    justify-content: start !important;
}

.reward-drop .ant-select-selector {
    padding: 8px 11px !important;
    height: 47px !important;
    background: #F9F9F9 !important;
}

.reword-border-drop .ant-select-selector {
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-right: none !important;
}
.scrollbarex::-webkit-scrollbar {
        display: none;
      
}

.input-style {
    width: 100%;
    background: #F9F9F9 !important;
    padding: 14px 17px;
    border: 1px solid #C3D3E2;
    border-radius: 5px;
    outline: none;
}

.input-style-border {
    width: 100%;
    background: #F9F9F9 !important;
    padding: 11.4px;
    border: 1px solid #C3D3E2;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;

    outline: none;
}

.code-lable {
    color: #888888;
    margin-bottom: 3px !important;
}

.reword-main .ant-picker {
    width: 100%;
    background: #F9F9F9;
}

.code-Box {
    width: 100%;
    height: 170px;
    background: #F9F9F9;
    border-radius: 5px;
    padding: 10px 17px;
    border: 1px solid #c3d3e2;
}

.code-text {
    font-size: 20px;
    font-weight: 600;

}

.re-text-area {
    width: 100%;
    height: 170px;
    background: #F9F9F9;
    border-radius: 5px;
    padding: 10px 17px;
    border: 1px solid #c3d3e2;
    resize: none;
}

.popup-picker .ant-picker {
    width: 100% !important;
}

.code-input {
    border: none;
    border-bottom: 1px solid black;
    width: 100%;
    background: transparent;
    font-size: 22px;
    font-weight: 700;
}

.ant-select-selection-search {
    display: flex;
    align-items: center;
}

ul.listType {
    list-style-type: square !important;
    list-style: square !important;

}
.user-select-box{
    background: rgba(0, 0, 0, 0.06);
    border: 1px solid rgba(5, 5, 5, 0.06);
    padding: 1px 7px;
    border-radius: 5px;
    height: fit-content;
}
.provider-close-icon{
    color: gray;
    font-size: 12px !important;
    margin-left: 3px;
}
.provider-close-icon:hover{
    color: black;
}

.three-line {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}

.two-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}
.one-line {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
}


.prociderimg {
    position: relative;
    display: inline-block;
    overflow: visible; /* Ensure the delete icons are visible */
}

/* General styles for cancel icons */
.cancel-icon,
.cancel-icon2 {
    position: absolute;
    color: #ff5d5d;
    background: #ffecec; /* Optional background for visibility */
    border-radius: 50%;
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
    opacity: 0; /* Hidden by default */
    transition: opacity 0.3s ease-in-out;
    z-index: 10; /* Ensure it is above everything */
}

/* Position for first delete icon */
.cancel-icon {
    top: 10px;
    right: 10px;
}

/* Position for second delete icon */
.cancel-icon2 {
    top: 3px;
    right: 1px;
}

/* Show icons only on hover */
.prociderimg:hover .cancel-icon,
.prociderimg:hover .cancel-icon2 {
    opacity: 1;
}
